<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button"  v-show="!config.isDetailDisplay">
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Warehouse:Warehouseinfo:Create'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="del" v-right-code="'Warehouse:Warehouseinfo'">删除</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="importWarehouseExcel" v-right-code="'Warehouse:Warehouseinfo:Importwarehouseinfo'">导入</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="exportWarehouseExcel" v-right-code="'Warehouse:Warehouseinfo:Importwarehouseinfo'">导出</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="warehouseInfoListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='WarehouseNo'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <DialogEx :options="importConfig" title="批量导入服务仓库" @onOk="onImportOk">
            <ImportWarehouse ref="importWarehouse" :options="importConfig" :dataSource="importDataSource"></ImportWarehouse>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadWarehouseInfoPageList", () => this.reloadPageList());
                this.Event.$on("onAdd", () => this.add());
            });
            this.initialize();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                importConfig: {
                    visible: false,
                    size: 'small',
                },
                importDataSource: {
                }
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/warehouse/warehouseinfo/searchwarehouselist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/warehouse/warehouseinfo/getwarehouseinfobyid", "get", { warehouseNo: row.WarehouseNo }, (data) => {
                    this.Event.$emit("clearEditWarehouseInfoForm");
                    data.Result.IsEditMember = null == data.Result.MemberId;
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add: function () {
                this.$ajax.send("omsapi/warehouse/warehouseinfo/getemptyservicewareh", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            del: function () {
                if (this.multipleSelection.length == 0) {
                    this.Utils.messageBox("未选择删除的仓库.");
                    return;
                }
                var This = this;
                this.Utils.confirm({
                    title: "是否确认删除?",
                    content: "删除后数据不可恢复,是否确认删除?"
                }, () => {
                    var ids = this.multipleSelection.map(item => item.WarehouseId);
                    this.$ajax.send("omsapi/warehouse/warehouseinfo/delete", "post", ids, (data) => {
                        this.initialize();
                        this.Utils.messageBox("删除成功.", "success");
                    });
                });
            },
            importWarehouseExcel() {
                var _this = this;
                _this.importConfig.visible = true;
                _this.importDataSource = {};
            },
            onImportOk: function (options) {
                var _this = this;
            },
            exportWarehouseExcel: function () {
              /*  var _this = this;
                var str = encodeURIComponent(JSON.stringify(_this.queryParam));
                var urlFile = "omsapi/warehouse/warehouseinfo/exportwarehouseinfo?queryParam=" + str;
                _this.Utils.export(urlFile); */
				
				var _this = this;
				var data = _this.queryParam;
				var str = encodeURIComponent(JSON.stringify(data));
				var urlFile = "omsapi/warehouse/warehouseinfo/exportfile?queryParam=" + str+ "&token=" + this.Utils.getToken();
				_this.Utils.export(urlFile);
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        },
        components: {
            "ImportWarehouse": resolve => { require(['./importwarehouseinfo.vue'], resolve) },
        }
    }
</script>
<style>

</style>